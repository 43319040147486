<template>
  <v-container>
    <v-row>
      <v-col
        :lg="4"
        :md="3"
        :cols="12"
      >
        <v-row>
          <v-col>
            <h1 class="display-4 primary--text">0</h1>
            <h3>Assets Booked MTD</h3>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <h1 class="primary--text">0</h1>
                <h3>Assets Collected MTD</h3>
              </v-col>
              <v-col>
                <h1 class="primary--text">0</h1>
                <h3>Assets Returned MTD</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :lg="8"
        :md="9"
        :cols="12"
      >
        <canvas style="height: 250px;" ref="servicePlans"></canvas>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'NewReport',

  data () {
    return {
      metrics: {},
      title: 'Service Plan Allocation'
    }
  },

  mounted () {
    this.getMetrics()
  },

  computed: {
    barBgColors () {
      if  (this.metrics.bg_colours) {
        return this.metrics.bg_colours
      }
      return [
        'rgba(0, 160, 155, 0.3)',
        'rgb(255, 148, 28, 0.3)',
        'rgb(33, 150, 243, 0.3)',
        'rgb(233, 30, 99, 0.3)'
      ]
    },

    barBorderColors () {
      if  (this.metrics.border_colours) {
        return this.metrics.border_colours
      }
      return [
        'rgba(0, 160, 155, 1)',
        'rgb(255, 148, 28, 1)',
        'rgb(33, 150, 243, 1)',
        'rgb(233, 30, 99, 1)'
      ]
    },

    chartData () {
      var datasets = []
      if (this.metrics.datasets) {
        var labels = [...Array(this.metrics.labels.length).keys()]
        this.metrics.datasets.forEach((dataset, key) => {
          var bgColors = []
          var borderColors = []
          labels.forEach(() => {
            bgColors.push(this.barBgColors[key])
            borderColors.push(this.barBorderColors[key])
          })
          datasets.push({
            ...{
              backgroundColor: bgColors,
              borderColor: borderColors,
              borderWidth: 1
            },
            ...dataset
          })
        })
        var bgColors = []
        var borderColors = []
        labels.forEach(() => {
          bgColors.push('rgb(103, 58, 183, 0.3)')
          borderColors.push('rgb(103, 58, 183, 1)')
        })
        datasets.push({
          label: 'Total',
          backgroundColor: bgColors,
          borderColor: borderColors,
          data: [0,0,0,0,0,0,0],
          borderWidth: 1
        })
      }
      return datasets
    },

    chartConfig () {
      return {
        type: 'bar',
        data: {
          labels: this.metrics.labels,
          datasets: this.chartData
        },
        options: {
          title: {
            display: true,
            text: this.title
          },
          legend: {
            labels: {
              filter: (item) => {
                return !item.text.includes('Total')
              }
            }
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: (tooltipItem, data) => {
                var label = data.datasets[tooltipItem.datasetIndex].label
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                var total = 0;
                for (var i = 0; i < data.datasets.length; i++)
                    total += data.datasets[i].data[tooltipItem.index];

                if (label === 'Total') {
                  return `${label} : ${total}`
                } else {
                  return `${label} : ${value}`
                }
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                suggestedMax: 50,
                beginAtZero: true,
                stepSize: 10
              }
            }]
          }
        }
      }
    }
  },

  methods: {
    mountChart () {
      new Chart(this.$refs.servicePlans, this.chartConfig)
    },

    getMetrics () {
      this.$api.get(`accounts/${this.$auth.account.id}/metrics/children`)
      .then(({ data: metrics }) => {
        this.metrics = metrics
        this.mountChart()
      })
    }
  }
}
</script>
